<template>
  <div class="dialog-reward dialog-wrap">
    <div
      class="dialog-reward__box dialog-box__animation dialog-content__box"
      @click.stop
    >
      <slot name="title">
      </slot>
      <div
        v-if="lang.topDesc"
        class="dialog-reward_top-desc"
      >
        {{ lang.topDesc }}
      </div>
      <div class="dialog-box__body">
        <div
          v-if="content.length"
          class="reward-list"
        >
          <div
            v-for="(item,index) in content"
            :key="index"
            class="reward"
          >
            <div class="reward-img">
              <img :src="item.img" />
              <div
                v-if="item.num !== undefined"
                class="reward-num"
                :class="numPosition"
                :data-txt="item.num"
              >
                {{ item.num }}
              </div>
            </div>

            <div
              v-if="item.bottom !== undefined"
              class="reward-bottom"
              :data-txt="item.bottom"
            >
              {{ item.bottom }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="lang.bottomDesc"
        class="dialog-reward_bottom-desc"
      >
        {{ lang.bottomDesc }}
      </div>
      <slot name="footer"></slot>

      <slot name="close">
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RewardContent',
  props: {
    title: {
      type: String,
      default: '',
    },
    lang: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: Array,
      default: () => ([]),
    },
    numPosition: {
      type: String,
      default: 'bottom-end',
    },
    // amtPosition: {
    //   type: String,
    //   default: 'bottom',
    // },
    // numPosition值解释：
    // top           # 顶部中间位置
    // top-start     # 顶部左侧位置
    // top-end       # 顶部右侧位置
    // left          # 左侧中间位置
    // left-start    # 左侧上方位置
    // left-end      # 左侧下方位置
    // right         # 右侧中间位置
    // right-start   # 右侧上方位置
    // right-end     # 右侧下方位置
    // bottom        # 底部中间位置
    // bottom-start  # 底部左侧位置
    // bottom-end    # 底部右侧位置
  },
}

</script>
