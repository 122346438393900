import Vue from 'vue'
import { version } from '../package.json'
import EventDialog from './packages/eventDialog'
import { Toast } from './packages/toast'
const components = [EventDialog]

const install = Vue => {
  if (install.installed) {
    return
  }
  components.forEach(component => {
    Vue.component(component.name, component)
  })
}

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}

export { EventDialog }

export default {
  version,
  install,
}

export class EventDialogClass {
  // 参数各个含义可以查看 dialog 内部的prop字段解释
  constructor(config) {
    const options = { ...(window.HDM.DialogGlobalOptions || {}), ...(config || {}) }
    if (!options.getContainer) {
      options.getContainer = () => document.body
    }
    options.visible = true
    this.props = options
    Array.isArray(EventDialogClass.instances) && EventDialogClass.instances.push(this)
  }

  // 获取当前的实例
  static getInstance(config) {
    if (!EventDialogClass.instance) {
      const options = { ...(window.HDM.DialogGlobalOptions || {}), ...(config || {}) }
      EventDialogClass.instance = new EventDialogClass(options)
    }
    return EventDialogClass.instance
  }

  // 弹出弹窗
  alert(config = { }) {
    const { onConfirm, onClose, onClosed, onOpen, onOpened, onCancel } = config
    // 如果存在取消的情况，那么就需要发出取消的事件
    typeof onCancel === 'function' && (this.props.customCancel = true)
    const dialogComponent = new Vue({
      render: h =>
        h(EventDialog, {
          ref: 'eventDialog', // 绑定 ref
          props: Vue.observable(this.props),
          on: {
            confirm: (data = {}) => {
              typeof onConfirm === 'function' && onConfirm(data)
              !onConfirm && this.resolve(true)
            },
            // 弹窗开启
            open: () => {
              typeof onOpen === 'function' && onOpen()
            },
            cancel: () => {
              if (!this.props.customCancel) {
                this.reject(false)
                this.props.destroyOnClose && this.dialogComponent.$destroy()
                try {
                  this.props.destroyOnClose && this.props.getContainer().removeChild(this.dialogComponent.$el)
                } catch (error) {
                  console.log(error, `${this.props.getContainer()}元素下没有找到对应的弹窗实例`)
                }
              } else {
                typeof onCancel === 'function' && onCancel()
                !onCancel && this.reject(true)
              }
            },
            // 弹窗开启动画结束
            opened: () => {
              typeof onOpened === 'function' && onOpened()
            },
            // 关闭弹窗
            close: () => {
              typeof onClose === 'function' && onClose()
            },
            // 关闭弹窗动画结束
            closed: () => {
              this.reject(false)
              typeof onClosed === 'function' && onClosed()
              this.props.destroyOnClose && this.dialogComponent.$destroy()
              try {
                this.props.destroyOnClose && this.props.getContainer().removeChild(this.dialogComponent.$el)
              } catch (error) {
                console.log(error, `${this.props.getContainer()}元素下没有找到对应的弹窗实例`)
              }
            },
          },
        }),
    })

    const promise = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
      dialogComponent.$mount()
      document.body.appendChild(dialogComponent.$el)
    })
    this.dialogComponent = dialogComponent
    return promise
  }

  // 调用中间状态
  done() {
    this.resolve()
  }

  // 主动关闭弹窗
  close() {
    this.dialogComponent.$refs.eventDialog && this.dialogComponent.$refs.eventDialog.closeDialog()
  }

  // 关闭所有弹窗
  static closeAllDialog() {
    Array.isArray(EventDialogClass.instances) && EventDialogClass.instances.forEach(instance => {
      instance.close()
    })
  }

  // 设置属性值，可以响应式
  setData(key, data) {
    this.props[key] = data
  }

  // 设置全局弹窗配置
  static setDefaultOptions(config) {
    window.HDM && (window.HDM.DialogGlobalOptions = config)
  }

  static resetDefaultOptions() {
    window.HDM && (window.HDM.DialogGlobalOptions = null)
  }
}

EventDialogClass.instances = []

const DialogTypeEnum = {
  /** 通用 */
  COMMON: 'CommonContent',
  /** 表格 */
  TABLE: 'TableContent',
  /** 奖励 */
  REWARD: 'RewardContent',
}

const dealParams = (params = {}) => {
  const dialogType = {
    common: DialogTypeEnum.COMMON,
    table: DialogTypeEnum.TABLE,
    reward: DialogTypeEnum.REWARD,
  }
  const globalConfig = window.HDM.DialogGlobalOptions || {}
  const getData = (key) => {
    // eslint-disable-next-line
    return params && params.hasOwnProperty(key) ? params[key] : globalConfig?.[key]
  }
  const config = {
    customClass: getData('customClass'),
    allowHtml: true,
    content: getData('content'),
    numPosition: getData('numPosition'),
    // amtPosition: getData('amtPosition'),
    lang: {
      title: getData('title'),
      confirmText: getData('confirmText'),
      bottomDesc: getData('bottomDesc'),
      topDesc: getData('topDesc'),
      cancelText: getData('cancelText'),
    },
    componentName: dialogType[getData('type')],
    closeOnClickOverlay: getData('closeOnClickOverlay'),
    showClose: getData('showClose'),
  }
  return {
    dialogType,
    globalConfig,
    config,
  }
}

// 为兼容旧版本弹窗的打开方法，不建议使用
export const showModal = (params = {}) => {
  const { config } = dealParams(params)
  const dialog = new EventDialogClass({ ...config })
  dialog.alert().then(() => {
    if (params.onConfirm && typeof params.onConfirm === 'function') {
      params.onConfirm(dialog)
    }
  }).catch(() => {
    if (params.onCancel && typeof params.onCancel === 'function') {
      params.onCancel(dialog)
    }
  })
}

// 新版本打开弹窗方法
export const showDialog = (params = {}, callback) => {
  const { config } = dealParams(params)
  const dialog = new EventDialogClass({ ...config })
  dialog.alert({
    onCancel: params.onCancel ? () => params.onCancel(dialog) : null,
    onClosed: params.onClosed ? () => params.onClosed(dialog) : null,
    onConfirm: params.onConfirm ? () => params.onConfirm(dialog) : null,
  }).then(() => {
    // eslint-disable-next-line
    typeof callback === 'function' && callback(true)
  }).catch(() => {
    // eslint-disable-next-line
    typeof callback === 'function' && callback(false)
  })
}
/** 以下两个是为了兼容旧的event-dialog，因此不能删除哦 */
window.EventDialogClass = EventDialogClass
window.DialogTypeEnum = DialogTypeEnum
/** 到这边兼容代码结束 */

window.HDM = {
  EventDialogClass: EventDialogClass,
  DialogTypeEnum: DialogTypeEnum,
  Toast: Toast,
  showDialog: showDialog,
  DialogGlobalOptions: null,
  ToastGlobalOptions: null,
  showModal: showModal,
}
