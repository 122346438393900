import './index.scss'
// 创建一个Toast对象
export const Toast = {
  isInit: false,
  params: null,
  // 初始化函数
  init() {
    // 创建遮罩层
    this.overlay = document.createElement('div')
    // 创建toast容器
    this.toastContainer = document.createElement('div')
    // 将toast容器添加到遮罩层中
    this.overlay.appendChild(this.toastContainer)
    // 将遮罩层添加到body中
    document.body.appendChild(this.overlay)
    this.isInit = true
  },

  // 显示toast消息
  show({ message, state, params = {} }) {
    this.params = { ...(window.HDM.ToastGlobalOptions || {}), ...(params || {}) }

    if (!this.isInit) {
      this.init()
    }

    this.overlay.className = this.params?.overlayClass ? `toast-overlay ${this.params.overlayClass}` : 'toast-overlay'
    this.overlay.style.zIndex = this.params?.zIndex || 2000
    this.toastContainer.className = this.params?.className ? `toast-container ${this.params.className}` : 'toast-container'

    // 清除之前的toast
    this.toastContainer.innerHTML = ''
    // 创建toast元素
    const toast = document.createElement('div')
    toast.className = `toast toast-${state}`
    // 如果是success或error状态，添加图标
    if (state === 'success' || state === 'error') {
      const icon = document.createElement('div')
      icon.className = 'toast-icon'
      toast.appendChild(icon)
    }
    // 如果是loading状态，添加加载动画
    if (state === 'loading') {
      const spinner = document.createElement('div')
      spinner.className = 'toast-icon'
      toast.appendChild(spinner)
    }
    // 添加文本
    const textNode = document.createTextNode(message)
    toast.appendChild(textNode)
    this.toastContainer.appendChild(toast)
    // 显示遮罩层
    this.overlay.style.display = 'flex'

    // 如果不是loading状态，则设置自动消失
    if (state !== 'loading') {
    // eslint-disable-next-line
      const duration = this.params?.hasOwnProperty('duration') ? this.params?.duration : 2000
      duration && setTimeout(() => {
        this.hide()
      }, duration)
    }
  },
  // 隐藏toast消息
  hide() {
    this.params?.onClose && this.params.onClose()
    this.overlay.style.display = 'none'
  },
  // 成功提示
  success(message, params = {}) {
    this.show({ message, state: 'success', params })
  },

  text(message, params = {}) {
    this.show({ message, state: 'text', params })
  },

  // 失败提示
  fail(message, params = {}) {
    this.show({ message, state: 'error', params })
  },

  // 加载提示
  loading(message = 'Loading...', params = {}) {
    this.show({ message, state: 'loading', params })
  },

  // 设置默认配置
  setDefaultOptions(options) {
    window.HDM && (window.HDM.ToastGlobalOptions = options)
  },

  resetDefaultOptions() {
    window.HDM && (window.HDM.ToastGlobalOptions = null)
  },
}
