<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="dialog-common dialog-wrap"
  >
    <div
      class="dialog-box__animation dialog-content__box"
      @click="handleClick"
    >
      <slot name="title">
      </slot>
      <div class="dialog-box__body">
        <slot name="content">
          <div
            v-if="!allowHtml"
          >
            {{ content }}
          </div>
          <div
            v-else
            v-html="DOMPurify.sanitize(content)"
          ></div>
        </slot>
      </div>
      <slot name="footer"></slot>
      <slot name="close">
      </slot>
    </div>
  </div>
</template>
<script>
import DOMPurify from 'dompurify'
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    allowHtml: {
      type: Boolean,
      default: false,
    },
    lang: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: String,
      default: '',
    },
    contentStopPropagation: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      DOMPurify,
    }
  },
  methods: {
    handleClick(event) {
      if (this.contentStopPropagation) {
        event.stopPropagation()
      }
    },
  },
}
</script>
